@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800&display=swap");

body,
input,
textarea {
  font-family: "Montserrat";
  color: #2E3B55
}

.container {
    position: relative;
    max-width: 100%;
    padding: 2rem;
    background-color: #fafafa;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .form {
    width: 100%;
    max-width: 820px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 0 20px 1px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    overflow: hidden;
    
  }

  .contact-info {
    padding: 2.3rem 2.2rem;
    position: relative;
  }
  
  .contact-info .title {
    color: #2E3B55;
  }

  .information {
    display: flex;
    color: #555;
    margin: 0.7rem 0;
    align-items: center;
    font-size: 0.95rem;
  }

  .contact-form {
    background-color: #2E3B55;
    position: relative;
  }

  form {
    padding: 2.3rem 2.2rem;
    z-index: 10;
    overflow: hidden;
    position: relative;
  }

  
.title {
    color: #fff;
    font-weight: 500;
    font-size: 1.5rem;
    line-height: 1;
    margin-bottom: 0.7rem;
  }
  
  .input-container {
    position: relative;
    margin: 1rem 0;
  }
  
  .input {
    width: 90%;
    outline: none;
    border: 2px solid #fafafa;
    background: white;
    padding: 0.6rem 1.2rem;
    color: #2E3B55;
    font-weight: 500;
    font-size: 0.95rem;
    letter-spacing: 0.5px;
    border-radius: 5px;
    transition: 0.3s;
  }
  
  textarea.input {
    padding: 0.8rem 1.2rem;
    min-height: 150px;
    border-radius: 5px;
    resize: none;
    overflow-y: auto;
  }
  
  .input-container label {
    position: absolute;
    top: 50%;
    left: 15px;
    transform: translateY(-50%);
    padding: 0 0.4rem;
    color: #2E3B55;
    font-size: 0.9rem;
    font-weight: 400;
    pointer-events: none;
    z-index: 1000;
    transition: 0.5s;
  }
  
  .input-container.textarea label {
    top: 1rem;
    transform: translateY(0);
  }
  
  .btn:hover {
    background-color: transparent;
    color: #fff;
  }
  
  .input-container span {
    position: absolute;
    top: 0;
    left: 25px;
    transform: translateY(-50%);
    font-size: 0.8rem;
    padding: 0 0.4rem;
    color: transparent;
    pointer-events: none;
    z-index: 500;
  }
  
  .input-container span:before,
  .input-container span:after {
    content: "";
    position: absolute;
    width: 10%;
    opacity: 0;
    transition: 0.3s;
    height: 5px;
    background-color: #2E3B55;
    top: 50%;
    transform: translateY(-50%);
  }
  
  .input-container span:before {
    left: 50%;
  }
  
  .input-container span:after {
    right: 50%;
  }
  
  .input-container.focus label {
    top: 0;
    transform: translateY(-50%);
    left: 25px;
    font-size: 0.8rem;
  }
  
  .input-container.focus span:before,
  .input-container.focus span:after {
    width: 50%;
    opacity: 1;
  }